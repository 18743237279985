import React from 'react'
import styled from '@emotion/styled'
import ShareButtons from '@components/ShareButtons'
import { StaticImage } from 'gatsby-plugin-image'
import { BsClock, BsClockHistory, BsPeople } from 'react-icons/bs'

const Wrapper = styled.div`
  padding: 1rem;
  margin: auto;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  line-height: 1.3;
  text-align: center;
  span {
    margin: 0 0.25rem;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: 5/1/7/7;
    text-align: left;
    margin: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.large}) {
    grid-area: 5/2/5/7;
    margin: 1rem 0 0 1rem;
  }
`

const Date = styled.span`
  display: inline-block;
  font-size: 0.9em;
`

const Dividers = styled.span`
  color: gainsboro;
  font-size: 0.75em !important;
`

const JumpToRecipe = styled.a`
  display: inline-block;
  font-size: 0.9em;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.neutral};
  padding: 2px 8px;
  margin-right: 8px;

  &:hover {
    color: #7c7a7a;
    text-decoration: underline;
  }

  &:visited {
    color: ${(props) => props.theme.colors.text};
  }
`

const ShareWrapper = styled.div`
  margin-top: 0.8em;
`

const MetaDescription = styled.h4`
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  line-height: 1.6em;

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    margin-top: 2rem;
    max-width: 400px;
  }
`

const RecipeDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    max-width: 450px;
  }
`

const RecipeDetailsItem = styled.div`
  padding: 2rem 0 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;

  &:nth-of-type(2)::before {
    content: ' ';
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-left: 1px solid #eaecf0;
  }

  &:nth-of-type(2)::after {
    content: ' ';
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-right: 1px solid #eaecf0;
  }
`

const IconWrapper = styled.div`
  margin: 0 auto;
`

const PostDetails = (props) => {
  return (
    <Wrapper>
      <Date>{props.date}</Date>
      <Dividers>//</Dividers>
      {/* <span>•</span> */}
      <JumpToRecipe href="#recipe-card">↓ Jump to Recipe</JumpToRecipe>
      <ShareWrapper>
        <ShareButtons
          title={props.title}
          url={props.url || `http://localhost:8000/`}
        />
      </ShareWrapper>
      <MetaDescription>{props.metaDescription}</MetaDescription>
      <RecipeDetails>
        <RecipeDetailsItem>
          <IconWrapper>
            <BsClock />
          </IconWrapper>
          Prep Time
          <span>{props.prepTime}</span>
        </RecipeDetailsItem>
        <RecipeDetailsItem>
          <IconWrapper>
            <BsClockHistory />
          </IconWrapper>
          Cook Time
          <span>{props.cookTime}</span>
        </RecipeDetailsItem>
        <RecipeDetailsItem>
          <IconWrapper>
            <BsPeople />
          </IconWrapper>
          Serving
          <span>{props.serving}</span>
        </RecipeDetailsItem>
      </RecipeDetails>
    </Wrapper>
  )
}

export default PostDetails
