import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  margin: 2em 0 0 0;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: 6/1;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: end;
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  a {
    background: ${(props) => props.theme.colors.primary};
    color: white;
    padding: 1em;
    border-radius: 2px;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      background: ${(props) => props.theme.colors.highlight};
      color: ${(props) => props.theme.colors.text};
    }
  }
`

const NavButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1em 0;
`
const NavPrev = styled(NavButtons)`
  margin-right: auto;
  order: 1;
`

const NavNext = styled(NavButtons)`
  text-align: right;
  margin-left: auto;
  order: 2;
`

const PreviousLink = styled(Link)`
  margin-right: auto;
`

const NextLink = styled(Link)`
  margin-left: auto;
`

const Title = styled.h4`
  letter-spacing: 0.1em;
  margin-bottom: 0.5em;
  max-width: 280px;
`

const PostLinks = (props) => {
  return (
    <Wrapper>
      <Box>
        {props.previous && (
          <NavPrev>
            <Title>{props.previous.title}</Title>
            <PreviousLink to={`${props.basePath}/${props.previous.slug}/`}>
              &#8592; Prev
            </PreviousLink>
          </NavPrev>
        )}
        {props.next && (
          <NavNext>
            <Title>{props.next.title}</Title>
            <NextLink to={`${props.basePath}/${props.next.slug}/`}>
              Next &#8594;
            </NextLink>
          </NavNext>
        )}
      </Box>
    </Wrapper>
  )
}

export default PostLinks
