import React from 'react'
import styled from '@emotion/styled'
import CommentForm from '@components/CommentForm'

const NestedComments = ({
  comment,
  handleInputChange,
  handleSubmit,
  onReplyingPost,
  modal,
  slug,
  loading,
  replying,
  formState,
}) => {
  const nestedComments = comment.replies.map((comment) => {
    return (
      <NestedComments
        key={comment._id}
        comment={comment}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        replying={replying}
        onReplyingPost={onReplyingPost}
        modal={modal}
        loading={loading}
        slug={slug}
        formState={formState}
      />
    )
  })

  return (
    <CommentContainer>
      <CommentWrapper>
        <CommentMeta>
          <CommentName>{comment.name}</CommentName> —{' '}
          <CommentDate>{comment.date}</CommentDate>
          <span>
            <ReplyButton onClick={() => onReplyingPost(comment._id)}>
              {replying && formState['fields[reply_to]'] == comment._id
                ? 'Cancel Reply'
                : 'Reply'}
            </ReplyButton>
          </span>
        </CommentMeta>
        <p>{comment.message}</p>
        {comment.rating != 0 && <Rated rating={comment.rating}></Rated>}
        {replying && formState['fields[reply_to]'] == comment._id && (
          <ReplyContainer>
            <p>
              REPLYING TO <CommentName>{comment.name}</CommentName>:
            </p>
            <CommentForm
              handleSubmit={handleSubmit}
              handleInputChange={handleInputChange}
              modal={modal}
              loading={loading}
              slug={slug}
              formState={formState}
            />
          </ReplyContainer>
        )}
        {nestedComments}
      </CommentWrapper>
    </CommentContainer>
  )
}

const CommentContainer = styled.div`
  border: 1px solid #ededed;
  padding: 0 1em;
  margin-top: 1em;
`

const CommentWrapper = styled.div`
  margin: 2em auto;
  line-height: 1.6;
  position: relative;
`

const CommentMeta = styled.div`
  text-align: left;
  text-transform: uppercase;
  margin: 0.5em auto;
`

const CommentName = styled.span`
  font-weight: 600;
`

const CommentDate = styled.span`
  font-size: 0.8em;
`

const ReplyContainer = styled.div`
  margin-top: 1em;
  p {
    font-size: 1.2em;
    text-decoration-line: underline;
  }
`

const ReplyButton = styled.button`
  color: #57696d;
  font-size: 0.75em;
`

const Rated = styled.div`
  display: inline-block;
  font-size: 20px;
  line-height: 1;

  &:before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(
      90deg,
      ${(props) => props.theme.colors.secondary}
        calc(${(props) => props.rating} / 5 * 100%),
      #fff calc(${(props) => props.rating} / 5 * 100%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export default NestedComments
