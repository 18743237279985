export const nestCommentsHelper = (queryComments) => {
  let indexedComments = {}

  queryComments.map((ogComment) => {
    const newComment = { ...ogComment.node, replies: [] }
    indexedComments[newComment._id] = { ...newComment }
  })

  if (Object.keys(indexedComments).length !== 0) {
    for (const [key, comment] of Object.entries(indexedComments)) {
      // Netlify deploy fails with the first condition alone; ¯\_(ツ)_/¯
      if (
        indexedComments[key]['reply_to'] !== '' &&
        indexedComments[indexedComments[key]['reply_to']] !== undefined
      ) {
        indexedComments[indexedComments[key]['reply_to']].replies.push(comment)
      }
    }
  }

  const final = Object.values(indexedComments).filter(
    (comment) => comment['reply_to'] == ''
  )
  return final
}
