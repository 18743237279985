import React from 'react'
import styled from '@emotion/styled'
import Rating from '@components/Rating'
import { FormEmail } from '@styles/globalComponents'

const CommentForm = ({
  handleInputChange,
  handleSubmit,
  modal,
  slug,
  loading,
  formState,
  ...props
}) => (
  <Form overlay={modal || loading} onSubmit={handleSubmit}>
    <input
      name="options[redirect]"
      type="hidden"
      value="https://therisingrose.com"
    />
    <input name="fields[slug]" type="hidden" value={slug} />
    {/* Rating func not present means that nested comments is calling CommentForm */}
    {props.onSaveRating && (
      <label>
        Rate This Recipe
        <RatingWrapper>
          {[1, 2, 3, 4, 5].map((index) => (
            <>
              <input
                type="radio"
                name="fields[rating]"
                value={formState['fields[rating]']}
              />
              <Rating
                key={index}
                index={index}
                rating={formState['fields[rating]']}
                hoverRating={props.hoverRating}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onSaveRating={props.onSaveRating}
              />
            </>
          ))}
        </RatingWrapper>
      </label>
    )}
    <label>
      Message*
      <textarea
        name="fields[message]"
        onChange={handleInputChange}
        value={formState['fields[message]']}
        cols="45"
        rows="8"
        required
      />
    </label>
    <label>
      Name*
      <Name
        name="fields[name]"
        type="text"
        onChange={handleInputChange}
        value={formState['fields[name]']}
        required
      />
    </label>
    <label>
      E-mail*
      <FormEmail
        name="fields[email]"
        type="email"
        onChange={handleInputChange}
        value={formState['fields[email]']}
        required
      />
    </label>
    <Submit type="submit">Post Comment!</Submit>
  </Form>
)

const Form = styled.form`
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;

  label {
    width: 100%;
    padding: 1em 0;
  }

  input:not([name='fields[rating]']),
  textarea {
    color: #696969;
    font-weight: 400;
    line-height: 1.1em;
    background-color: ${(props) => props.theme.colors.background};
    margin-bottom: 0.6em;
    padding: 1em;
    border: 2px solid #efece6;
    width: 100%;
  }

  &:before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${(props) => (props.overlay ? '.8' : '0')};
    visibility: ${(props) => (props.overlay ? 'visible' : 'hidden')};
  }
`
const Name = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${(props) => props.theme.screen.small}) {
    width: 49%;
  }
`

const Submit = styled.button`
  color: #ffffff !important;
  letter-spacing: 0.05em;
  line-height: 1.2;
  font-weight: 700;
  background-color: ${(props) => props.theme.colors.secondary};
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  cursor: pointer;
  margin-top: 0.5em;
  padding: 1em 1.2em;
  text-decoration: none !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
`

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
`

export default CommentForm
