import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import StarIcon from '../../icons/StarIcon'

const Rating = (props) => {
  const {
    index,
    rating,
    hoverRating,
    onMouseEnter,
    onMouseLeave,
    onSaveRating,
  } = props

  const fill = useMemo(() => {
    if (hoverRating >= index || (!hoverRating && rating >= index)) {
      return '#d1a19d'
    } else 'none'
  }, [rating, hoverRating])

  return (
    <Container
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}
    >
      <StarIcon fill={fill} />
    </Container>
  )
}

const Container = styled.div`
  margin: 2em auto;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
`

export default Rating
