import React, { useState } from 'react'
import styled from '@emotion/styled'
import { nestCommentsHelper } from '../../hooks/helpers'
import axios from 'axios'
import NestedComments from '../NestedComments'
import BoilingPot from '@static/gif/boilingpot.gif'
import CommentForm from '@components/CommentForm'

const Comments = ({ slug, commentsData, title }) => {
  const { edges: queryComments, totalCount: totalCommentCount } = commentsData
  const orderedComments = nestCommentsHelper(queryComments)

  const [formState, setFormState] = useState({
    'fields[rating]': 0,
    'fields[name]': '',
    'fields[email]': '',
    'fields[message]': '',
    'fields[reply_to]': '',
  })
  const [hoverRating, setHoverRating] = useState(0)
  const [modal, showModal] = useState(false)
  const [replying, setReplying] = useState(false)
  const [loading, setLoading] = useState(false)

  const onMouseEnter = (index) => setHoverRating(index)
  const onMouseLeave = () => setHoverRating(0)
  const onSaveRating = (index) =>
    setFormState({ ...formState, 'fields[rating]': index })

  const handleInputChange = (event) => {
    const { name, value } = event.target

    setFormState({
      ...formState,
      [name]: value,
    })
  }

  const onReplyingPost = (parentId) => {
    if (parentId != formState['fields[reply_to]']) {
      setFormState({ ...formState, 'fields[reply_to]': parentId })
      setReplying(true)
    } else {
      setFormState({ ...formState, 'fields[reply_to]': '' })
      setReplying(false)
    }
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)

    axios({
      method: 'POST',
      url:
        'https://the-whistling-cook.herokuapp.com/v2/entry/wyrd00/Gatsby-Contentful-V1/master/reviews',
      data: encode({ 'fields[slug]': slug, replies: [], ...formState }),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .then((response) => {
        setLoading(false)
        resetInputData()
        console.log(response)
        console.log(
          'Thanks! Your comment will be published after it has been reviewed.'
        )
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        console.log(`An error occured when posting your comment`)
      })
  }

  const resetInputData = () => {
    showModal(true)
    setFormState({
      'fields[name]': '',
      'fields[email]': '',
      'fields[message]': '',
      'fields[rating]': 0,
    })
  }

  const closeModal = () => {
    showModal(false)
  }

  return (
    <Container>
      <Heading>Leave a Thought!</Heading>
      <CommentNote>
        Your email address will not be published. Required fields are marked *
      </CommentNote>
      {!replying && (
        <CommentForm
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onSaveRating={onSaveRating}
          hoverRating={hoverRating}
          modal={modal}
          loading={loading}
          slug={slug}
          formState={formState}
        />
      )}
      {totalCommentCount != 0 && (
        <TotalComments>
          <span>
            {totalCommentCount}{' '}
            {totalCommentCount == 1 ? 'comment' : 'comments'} on&nbsp;
          </span>
          {title}
        </TotalComments>
      )}
      {orderedComments.map((comment) => (
        <NestedComments
          key={comment._id}
          comment={comment}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          replying={replying}
          onReplyingPost={onReplyingPost}
          modal={modal}
          loading={loading}
          slug={slug}
          formState={formState}
        />
      ))}
      <Loading visible={loading}>
        <img src={BoilingPot} alt="Boiling pot loading" />
        <p>Please wait as your comment simmers ...</p>
      </Loading>

      <Modal visible={modal}>
        <p>
          Thank you! Your comment will be published after it has been
          reviewed.❤️
        </p>
        <Button onClick={closeModal}>Okay</Button>
      </Modal>
    </Container>
  )
}

const Container = styled.section`
  padding: 1em 0;
  max-width: ${(props) => props.theme.sizes.maxWidth};
  border-top: 1px solid #ededed;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: 7/1;
  }
`

const Heading = styled.h3`
  font-size: 1.5em;
  padding-bottom: 1em;
`

const CommentNote = styled.p`
  padding: 0 0 1em;
  font-size: 1.1em;
  line-height: 1.6;
`

const TotalComments = styled.h2`
  margin: 1em auto;
  font-size: 1.5em;
  font-style: italic;
  font-weight: 400;
  span {
    font-style: normal;
  }
`

const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${(props) => props.theme.screen.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const Loading = styled.div`
  background: #cb6464;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${(props) => props.theme.screen.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
    color: white;
    text-align: center;
  }
`

const Button = styled.div`
  background: ${(props) => props.theme.colors.text};
  font-size: 1em;
  display: inline-block;
  margin: 0 auto;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  padding: 1em;
  border-radius: 2px;
  text-decoration: none;
  transition: 0.2s;
  z-index: 99;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${(props) => props.theme.colors.highlight};
  }
`

export default Comments
