import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const Bold = ({ children }) => (
  <span>
    <strong>{children}</strong>
  </span>
)
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = getImage(node.data.target)

      return <GatsbyImage image={image} alt={node.data.target.contentful_id} />
    },
  },
}

const PageRecipe = ({ recipe }) => {
  return (
    <Container>
      <RecipeBody id="recipe-card">
        {recipe && renderRichText(recipe, options)}
      </RecipeBody>
    </Container>
  )
}

const Container = styled.div`
  margin: 1em auto;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  position: relative;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: 2/1;
  }
`

const RecipeBody = styled.section`
  margin: 0 auto;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  h1,
  h2,
  h3,
  h4 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1.15em;
  }
  h4 {
    text-transform: uppercase;
  }

  p {
    line-height: 1.4;
    margin: 0 0 1.2em 0;
  }

  a {
    transition: 0.2s;
    color: ${(props) => props.theme.colors.text};
    &:hover {
      color: ${(props) => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      margin-left: 1em;
      // &:last-child {
      //   margin: 0;
      // }
    }
  }

  ol {
    counter-reset: list;
    
    li {
      line-height: 1.25;
      margin-left: 2em;
      position: relative;
      
      p::before {
          content: counter(list);
          counter-increment: list;
          position: absolute;
          border-radius: 50%;
          width: 2em;
          background-color: ${(props) => props.theme.colors.text};
          color: ${(props) => props.theme.colors.background};
          left: -25px;
          transform: translateX(-50%);
          line-height: 2.1em;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
          top: 4px;
        }
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${(props) => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${(props) => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }
`

export default PageRecipe
