import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
import { AnimatedLink } from '@styles/globalComponents'

const PageAbout = () => {
  return (
    <Aside>
      <Container>
        <Link to="/about">
          <StaticImage
            src="../../static/images/olivia-rose-about-side.jpg"
            alt="About Olivia Rose"
            placeholder="blurred"
            objectFit="cover"
          />
        </Link>
        <Title>Hi, I'm Olivia</Title>
        <Description>
          I am passionate about baking and I love watching the smile on someone’s face grow when I share my creations with them! 
          This blog is a compilation of my favorite, tried and true recipes and with all my tips and tricks to help you master them. 
          I love incorporating flavors from the local ingredients of my home, Hawaii, and I hope you will love these recipes as much as I do!
        </Description>
        <AnimatedLink>
          <Link to="/about">More</Link>
        </AnimatedLink>
      </Container>
    </Aside>
  )
}

const Aside = styled.aside`
  max-width: 400px;
  margin: 2em auto;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    max-width: 280px;
    grid-column: 2;
    grid-row: 1 / span 2;
  }
`

const Container = styled.div`
  text-align: center;
  line-height: 1.6;
`

const Title = styled.h3`
  padding: 0.5em;
`

const Description = styled.p`
  // padding: 0.5em;
  font-size: 0.85em;
`

export default PageAbout
