import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

const BgImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${(props) => props.theme.screen.medium}) {
    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
  }
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    grid-area: 2/6/9/13;
    max-height: 800px;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.large}) {
    grid-area: 2/7/9/12;
  }
`

const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.header};
  z-index: 2;
  font-size: 1.7em;
  text-transform: capitalize;
  position: absolute;
  width: 100%;
  max-width: ${(props) => props.theme.sizes.maxWidthCentered};
  padding: 0 1rem;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    color: black;
    font-size: 2.5em;
    grid-area: 3/1/5/7;
    text-align: left;
    position: static;
    transform: none;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.large}) {
    grid-area: 4/2/5/7;
  }
`

const Hero = (props) => (
  <>
    <BgImg
      image={props.image.gatsbyImageData}
      alt={props.title}
      backgroundColor={'#eeeeee'}
    />
    <Title>{props.title}</Title>
  </>
)

export default Hero
